<template>
  <div v-clickout="toggleDropdown"
       class="games-dropdown-filter g-dropdown g-dropdown-europa"
       :class="{'active': selectedValue === translationKey, 'disabled' : disabled}"
       @click="toggleDropdown(translationKey)">
    <button v-if="value.title || value.name"
            :disabled="disabled"
            class="g-dropdown-input"
            v-text="value.title || value.name" />
    <button v-else
            :disabled="disabled"
            class="g-dropdown-input dropdown-header">
      {{ translationKey | translate }}
    </button>
    <div class="g-dropdown-droplist">
      <div v-if="dropdownList.length > 10"
           class="g-droplist-dropoptions">
        <input v-model="searchTerm"
               :placeholder="'filter_search_field' | translate"
               class="g-droplist-search"
               @input="filterItems()"
               @click.stop>
      </div>
      <div class="g-droplist-dropoptions">
        <button v-for="(item, i) in filteredItems"
                :key="i"
                class="g-droplist-dropoption"
                :value="item.title || item.name"
                :class="{'active': (value && value.title === item.title) && (value && value.name === item.name)}"
                @click="$emit('input', item)">
          {{ item.title || item.name }}
          <i class="g-droplist-dropoption-icon g-icon g-icon-check-d" />
        </button>
      </div>
      <button class="table-filter-clear-button g-button g-button-earth"
              @click="$emit('input', {})">
        {{ 'filter_button_reset_filter' | translate }}
      </button>
    </div>
  </div>
</template>

<script>
import clickout from '../directives/clickout';

export default {
  name: 'FilterDropdown',
  directives: {
    clickout,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        if (this?.translationKey
          && this.translationKey.includes('company')
          && this.dropdownList.length === 1
        ) {
          return this.dropdownList[0];
        }
        return {};
      },
    },
    dropdownList: {
      type: Array,
      default: () => ([]),
    },
    translationKey: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dropdown: false,
    filteredItems: [],
    searchTerm: '',
    selectedValue: '',
  }),
  methods: {
    toggleDropdown(filter) {
      this.filteredItems = this.dropdownList;
      this.selectedValue = filter;
      this.dropdown = !this.dropdown;

      if (!this.dropdown) {
        this.resetSearch();
        this.selectedValue = '';
      }
    },
    filterItems() {
      const term = this.searchTerm;

      if (term === '') {
        this.resetSearch();
        return;
      }

      this.filteredItems = this.dropdownList.filter((item) => {
        if (item.title) {
          return item.title.toLowerCase().indexOf(term.toLowerCase()) >= 0;
        }
        return item.name.toLowerCase().indexOf(term.toLowerCase()) >= 0;
      });
    },
    resetSearch() {
      this.searchTerm = '';
      this.filteredItems = this.dropdownList;
    },
  },
};
</script>

<style lang="scss" scoped>
.games-dropdown-filter {
  width: auto !important;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.g-dropdown.g-dropdown-europa .g-dropdown-input,
.g-dropdown.g-dropdown-ganymede .g-dropdown-input {
  color: $font-primary-1;
  font-weight: bold;
  font-size: $fs-300;
  background: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.g-dropdown.g-dropdown-europa.active .g-dropdown-input,
.g-dropdown.g-dropdown-ganymede.active .g-dropdown-input {
  color: #fff;
  background-color: $accent;
}

.g-dropdown-input {
  line-height: 1.45em !important;

  &.dropdown-header {
    font-weight: normal !important;
  }
}

.g-dropdown-droplist {
  font-size: $fs-300;
  text-align: left;

  &::after {
    content: "";
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    right: 1rem;
    height: 0.75rem;
  }
}

.g-droplist-dropoption {
  text-align: left;
  line-height: 1.5 !important;
  width: 100%;
  min-width: 10rem;
  padding-left: 1rem !important;
  padding-right: 1.5rem !important;
  cursor: pointer;

  &:focus {
    outline:none;
  }
}

.table-filter-clear-button {
  width: 100%;
  border-top: 1px solid #fff;
  padding: 1em 0;
}
</style>
