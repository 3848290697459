<template>
  <div class="filter fluid-container">
    <div class="filter-search"
         :class="{'disabled': !isFilterEnabled}">
      <div class="g-search">
        <input type="text"
               class="g-search-input search-games-field"
               :value="filters.search"
               :placeholder="'filter_search_field' | translate"
               @input="onFilterChange($event.target.value, 'search')">
        <i class="g-search-icon g-icon g-icon-search" />
      </div>
      <i v-if="filters.search"
         class="g-icon g-icon-close-a"
         @click="onFilterChange('','search')" />
    </div>
    <div class="filter-dropdowns">
      <filter-dropdown v-if="filterConfig.company"
                       :value="filters.company"
                       :dropdown-list="companies"
                       translation-key="filter_company_dropdown"
                       @input="onFilterChange($event, 'company')" />
      <filter-dropdown v-if="filterConfig.category"
                       :value="filters.category"
                       :dropdown-list="categories"
                       translation-key="filter_category_dropdown"
                       :disabled="!isFilterEnabled"
                       @input="onFilterChange($event, 'category')" />
      <filter-dropdown v-if="filterConfig.type"
                       :value="filters.type"
                       :dropdown-list="types"
                       translation-key="filter_type_dropdown"
                       :disabled="!isFilterEnabled"
                       @input="onFilterChange($event, 'type')" />
      <filter-dropdown v-if="filterConfig.aggregator"
                       :value="filters.aggregator"
                       :dropdown-list="aggregators"
                       translation-key="filter_aggregator_dropdown"
                       :disabled="!isFilterEnabled"
                       @input="onFilterChange($event, 'aggregator')" />
      <filter-dropdown v-if="filterConfig.provider"
                       :value="filters.provider"
                       :dropdown-list="providers"
                       translation-key="filter_provider_dropdown"
                       :disabled="!isFilterEnabled"
                       @input="onFilterChange($event, 'provider')" />
      <filter-dropdown v-if="filterConfig.enabled"
                       :value="filters.enabled"
                       :dropdown-list="statuses"
                       translation-key="filter_status_dropdown"
                       :disabled="!isFilterEnabled"
                       @input="onFilterChange($event, 'enabled')" />
      <filter-dropdown v-if="filterConfig.active"
                       :value="filters.active"
                       :dropdown-list="activities"
                       translation-key="filter_activity_dropdown"
                       :disabled="!isFilterEnabled"
                       @input="onFilterChange($event, 'active')" />
      <button class="g-button filters-reset-button"
              title="Reset filters"
              @click="resetFilters()">
        <i class="g-icon g-icon-filter-d" />
      </button>
    </div>
    <div class="filter-togglers">
      <app-toggle v-if="filterConfig.tournament"
                  :value="filters.tournament"
                  translation-key="filter_tournament_selector"
                  :disabled="!isFilterEnabled"
                  @input="onFilterChange($event, 'tournament')" />
      <app-toggle v-if="filterConfig.new"
                  :value="filters.new"
                  translation-key="filter_new_selector"
                  :disabled="!isFilterEnabled"
                  @input="onFilterChange($event, 'new')" />
      <app-toggle v-if="filterConfig.mobile"
                  :value="filters.mobile"
                  translation-key="filter_mobile_selector"
                  :disabled="!isFilterEnabled"
                  @input="onFilterChange($event, 'mobile')" />
      <app-toggle v-if="filterConfig.live"
                  :value="filters.live"
                  translation-key="filter_live_selector"
                  :disabled="!isFilterEnabled"
                  @input="onFilterChange($event, 'live')" />
      <app-toggle v-if="filterConfig.standalone"
                  :value="filters.standalone"
                  translation-key="filter_standalone_selector"
                  :disabled="!isFilterEnabled"
                  @input="onFilterChange($event, 'standalone')" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _isBoolean from 'lodash/isBoolean';
import _isObject from 'lodash/isObject';
import _forEach from 'lodash/forEach';
import FilterDropdown from './FilterDropdown';
import AppToggle from './shared/AppToggle';

export default {
  name: 'AppFilter',
  components: {
    FilterDropdown,
    AppToggle,
  },
  props: {
    filterConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    moduleName: '',
    filters: {},
    defaultFilters: {
      search: '',
      company: {},
      category: {},
      type: {},
      aggregator: {},
      provider: {},
      active: {},
      enabled: {},
      new: false,
      mobile: true,
      live: false,
      standalone: true,
      tournament: false,
    },
    defaultQueryProperties: ['search', 'category', 'type', 'aggregator', 'provider',
      'active', 'enabled', 'new', 'mobile', 'standalone', 'live', 'tournament'],

    filterDataCollections: {
      category: 'categories',
      type: 'types',
      aggregator: 'aggregators',
      provider: 'providers',
      enabled: 'statuses',
      active: 'activities',
    },
    filterProperties: {
      id: ['category', 'aggregator', 'type'],
      name: ['category', 'aggregator', 'provider'],
      value: ['active', 'enabled'],
    },
  }),
  computed: {
    ...mapState({
      companies: (state) => state.filters.companies,
      types: (state) => state.filters.types,
      statuses: (state) => state.filters.statuses,
      activities: (state) => state.filters.activities,
    }),
    ...mapGetters(['aggregators', 'categories', 'providers', 'isTPA']),
    isCompanySelected() {
      return this.$route.params.companyId;
    },
    isFilterEnabled() {
      return this.isTPA || this.isCompanySelected;
    },
    queries() {
      const queryObject = {
        name: this.moduleName,
        params: {
          companyId: this.filterConfig.company && this.filters.company.companyPlatformId,
        },
        query: {},
      };

      this.defaultQueryProperties.forEach((property) => {
        if (!this.filterConfig[property] || (_isEmpty(this.filters[property]) && !_isBoolean(this.filters[property]))) return;

        queryObject.query[property] = (_isObject(this.filters[property]))
          ? this.getFilterObjectValue(property) : this.filters[property];
      });

      return queryObject;
    },
  },
  created() {
    this.moduleName = this.$route.name;
    if (!_isEmpty(this.categories)) { this.setActiveFilters(); }
  },
  methods: {
    onFilterChange(value, filter) {
      if (this.filters[filter] && _isEqual(this.filters[filter], value)) return;

      this.filters[filter] = value;

      this.updateFilters({ isCompanyChanged: filter === 'company' });
    },
    setFilterParams() {
      const query = { ...this.$route.query };
      _forEach(query, (item, key) => {
        query[key] = this.toBool(item);
      });

      const filterParams = { ...this.$route.params, ...query };
      this.$store.dispatch('storeFilterParams', filterParams);
    },
    resetFilters() {
      if (_isEqual(this.filters, this.defaultFilters)) return;
      this.filters = _cloneDeep(this.defaultFilters);
      this.updateFilters({ isCompanyChanged: false });
    },
    updateRouter() {
      if (_isEqual(_pick(this.$route, ['name', 'params', 'query']), this.queries)) return;
      this.$router.replace(this.queries)
        .catch((error) => { console.log(`***Error, updateRouter: ${error.message}`); });
    },
    updateFilters({ isCompanyChanged }) {
      this.updateRouter();
      this.setFilterParams();
      this.$emit('filtersChanged');
      if (isCompanyChanged) this.$emit('companyChanged');
    },
    getFilterObjectValue(property) {
      if (this.filterProperties.id.includes(property) && !this.isTPA) { // category, aggregator, type, not TPA
        return this.filters[property].id;
      }

      if (this.filterProperties.name.includes(property)) { // tpa category, tpa aggregator, provider
        return this.filters[property].name;
      }

      if (this.filterProperties.value.includes(property)) { // active, enabled
        return this.filters[property].value;
      }

      return this.filters[property].id; // tpa type
    },
    findFilterDataObject(property, value) {
      const collection = this.filterDataCollections[property];

      if (this.filterProperties.id.includes(property) && !this.isTPA) { // category, aggregator, type, not TPA
        return this[collection].find((obj) => obj.id === parseInt(value, 10));
      }

      if (this.filterProperties.name.includes(property)) { // tpa category, tpa aggregator, provider
        return this[collection].find((obj) => obj.name === value);
      }

      if (this.filterProperties.value.includes(property)) { // active, enabled
        return this[collection].find((obj) => obj.value === this.toBool(value));
      }

      return this[collection].find((obj) => obj.id === parseInt(value, 10)); // tpa type
    },
    setCompanyFilter(id) {
      const companyObject = this.companies.find((company) => company.companyPlatformId === id);
      if (!_isEmpty(companyObject)) {
        this.filters.company = companyObject;
      } else {
        this.filters.company.companyPlatformId = id;
      }
    },
    checkInitialCompanyFilter(companyId) {
      if (this.companies.length === 1) {
        const [company] = this.companies;
        this.filters.company = company;
      } else if (this.filterConfig.company && companyId) {
        this.setCompanyFilter(companyId);
      }
    },
    setActiveFilters() {
      const filterParams = { ...this.$route.query };
      const companyParams = { ...this.$route.params };
      this.filters = _cloneDeep(this.defaultFilters);

      this.checkInitialCompanyFilter(companyParams.companyId);

      Object.entries(filterParams).forEach((entry) => {
        const [property, value] = entry;

        if (!this.filterConfig[property] || (!value && !_isBoolean(value))) return;

        if (_isObject(this.filters[property])) {
          this.filters[property] = this.findFilterDataObject(property, value);
        } else if (_isBoolean(this.filters[property])) {
          this.filters[property] = this.toBool(value);
        } else {
          this.filters[property] = value;
        }
      });

      this.updateFilters({ isCompanyChanged: true });
    },
    toBool(value) {
      if (value === 'false') return false;
      if (value === 'true') return true;

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  width: 100%;
  display: grid;
  row-gap: 1rem;
  margin: 1.25em 0 0.6125em 0;

  @include breakpoint-large{
    flex-direction: row;
    grid-template-columns: 0.3fr 1fr;
  }

  &-search {
    position: relative;
    box-shadow: $shadow-outline;
    background: $brand-lighter;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    .g-icon.g-icon-close-a {
      position: absolute;
      width: 3em;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $font-secondary-1;
      cursor: pointer;
      transition: color 200ms $ease-out-cubic;

      &:hover {
        color: darken(#fff, 10%);
      }
    }
  }

  &-dropdowns {
    width: 100%;
    display: flex;
    border-radius: $br-sm;
    box-shadow: $shadow-outline;
    background: #fff;
    flex-wrap: wrap;

    @include breakpoint-large {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      box-shadow: $shadow-outline;
    }

    .g-dropdown {
      @include breakpoint-large {
        &:first-child {
          margin-left: auto;
        }
      }
    }
  }

  &-togglers {
    margin-top: 0.5rem;
    grid-column: 1 / -1;
    justify-self: end;

    display: flex;
    flex-wrap: wrap;
    font-size: $fs-200;

    .toggle-container {
      margin-right: 0.75rem;
    }
  }
}

.filters-reset-button {
  width: 3.125em;
  color: $font-primary-1;
  padding: 0;
  cursor: pointer;
  transition: color 250ms $ease-out-cubic, background-color 250ms $ease-out-cubic;
  margin-left: auto;

  @include breakpoint-large {
      margin-left: 0;
  }

  &:hover {
    color: $font-secondary-1;
    background: $font-primary-1;
  }

  &:focus {
    outline: none;
  }
}
</style>
